<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">예약일자</div>
            <ul class="content">
              <li class="item date">
                <input-date
                    format="YYYY-MM-DD"
                    type="lookup-condition"
                    v-model="searchConditions.resveDate"
                />
              </li>
              <li class="item text">
                (<span
                  :style="{
                    color: getDayOfWeekCaptionColor(
                      new Date(searchConditions.resveDate)
                    ),
                  }"
              >{{
                  getDayOfWeekCaption(new Date(searchConditions.resveDate))
                }}</span
              >) (<span
                  :style="{
                    color: getWeekdayWeekendCaptionColor(
                      new Date(searchConditions.resveDate)
                    ),
                  }"
              >{{
                  getWeekdayWeekendCaption(
                      new Date(searchConditions.resveDate),
                  )
                }}</span
              >)
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                          type="checkbox"
                          id="notResveRemarksFlag"
                          v-model="isLineOption"
                          value="true"
                          @change="fetch"
                      />
                      <i/>
                      <div class="label">라인옵션</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field" v-if="false">
            <div class="title">예약자명 표기</div>
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                          type="radio"
                          v-model="resveTransNameDiv"
                          value="RESVE"
                      />
                      <i/>
                      <div class="label">예약자</div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                          type="radio"
                          v-model="resveTransNameDiv"
                          value="TRANS"
                      />
                      <i/>
                      <div class="label">위임자</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field" v-if="false">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                          type="checkbox"
                          id="notContactTellFlag"
                          v-model="notContactTellFlag"
                          value="true"
                          :disabled="statusDiv === 'PART'"
                      />
                      <i/>
                      <div class="label">연락처 제외</div>
                    </label>
                  </li>
                </ul>
              </li>
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                          type="checkbox"
                          id="notResveRemarksFlag"
                          v-model="notResveRemarksFlag"
                          value="true"
                      />
                      <i/>
                      <div class="label">비고 제외</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              @click.native="onReservationStatusViewClicked"
          >
            조회
          </erp-button>
        </div>
        <ul class="lookup-condition" v-if="false">
          <li class="field">
            <div class="title">출력</div>
            <ul class="content">
              <li class="item check">
                <ul class="check">
                  <li>
                    <label>
                      <input
                          type="radio"
                          value="S"
                          v-model="isSmallMode"
                          name="isSmallMode"
                      />
                      <i></i>
                      <div class="label">작게</div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                          type="radio"
                          value=""
                          v-model="isSmallMode"
                          name="isSmallMode"
                      />
                      <i></i>
                      <div class="label">크게</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">코스구분</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                    ref="courseDiv"
                    v-model="courseDiv"
                    :dataSource="courseDivOptions"
                    :fields="commonCodeFields"
                    @change="onCourseDivChanged"
                />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="true"/>
      <article class="body-article">
        <section class="article-section">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">예약 현황(경기) 목록</div>
              <div class="header-caption">[{{ reservationStatusList.length | numberWithCommas }}건]</div>
              <ul class="header-label">
                <li class="field">
                  <div class="title">시간</div>
                  <ul class="content">
                    <li class="item">
                      <i
                          v-bind:style="{
                            'background-color': commonCodesGetColorValue(
                              'CANCEL_DIV',
                              'RAIN'
                            ),
                          }"
                      ></i>
                      <div class="label">우천취소</div>
                    </li>
                    <li class="item">
                      <i
                          v-bind:style="{
                            'background-color': commonCodesGetColorValue(
                              'TIME_STATUS',
                              'HOLD'
                            ),
                          }"
                      ></i>
                      <div class="label">홀딩</div>
                    </li>
                  </ul>
                </li>
                <li class="field" style="position: relative">
                  <label>
                    <input
                        type="checkbox"
                        v-model="coursePart.chapter1"
                        @change="gridFilterCheckChapter"
                    />
                    <i
                        style="background:white; margin-top: 5px;"
                    ></i>
                    <div class="title">1부</div>
                  </label>
                  <ul class="content">
                    <li class="item">
                      <div class="label">
                        {{reservationStatusInfo.allPartDiv1TeamCount || 0}}팀
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="field" style="position: relative">
                  <label>
                    <input
                        type="checkbox"
                        v-model="coursePart.chapter2"
                        @change="gridFilterCheckChapter"
                    />
                    <i
                        style="background:white; margin-top: 5px"
                    ></i>
                    <div class="title">2부</div>
                  </label>
                  <ul class="content">
                    <li class="item">
                      <div class="label">
                        {{reservationStatusInfo.allPartDiv2TeamCount || 0}}팀
                      </div>
                    </li>
                  </ul>
                </li>
                <li
                  class="field"
                  style="position: relative"
                  v-if="partDiv.filter(item => item.comCode === '3').length > 0"
                >
                  <label>
                    <input
                        type="checkbox"
                        v-model="coursePart.chapter3"
                        @change="gridFilterCheckChapter"
                    />
                    <i
                        style="background:white; margin-top: 5px"
                    ></i>
                    <div class="title">3부</div>
                  </label>
                  <ul class="content">
                    <li class="item">
                      <div class="label">
                        {{reservationStatusInfo.allPartDiv3TeamCount || 0}}팀
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">총팀수</div>
                  <ul class="content">
                    <li class="item">
                      <div class="label" style="font-weight: bold">
                        {{reservationStatusInfo.allPartDivAllTeamCount || 0}}팀
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      button-div="PRINT"
                      :is-shortcut-button="true"
                      @click.native="print"
                  >
                    인쇄
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="excel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-caption">
            <div class="caption-navigation" v-if="false">
              <ejs-tab
                  ref="reservationStatusDivTap"
                  :items="reservationStatusDivTapList"
                  :showCloseButton="false"
                  heightAdjustMode="Auto"
                  overflowMode="Popup"
                  @selected="tabSelected"
              />
            </div>
          </div>
          <div class="section-body">
            <div
                v-show="statusDiv === 'COURSE'"
                class="body-grid"
            >
              <ejs-grid-wrapper
                  ref="reservationStatusGrid"
                  :allowExcelExport="true"
                  :allowGrouping="true"
                  :allowResizing="true"
                  :provides="reservationStatusProps.provides"
                  :groupSettings="reservationStatusProps.groupSettings"
                  :columns="reservationStatusProps.columns"
                  :dataSource="reservationStatusList"
                  :aggregates="reservationStatusProps.aggregates"
                  @headerCellInfo="reservationStatusGridHeaderCellInfo"
                  @queryCellInfo="reservationStatusGridQueryCellInfo"
              />
            </div>
            <div
                v-show="statusDiv === 'PART'"
                class="body-grid"
            >
              <ejs-grid-wrapper
                  ref="reservationTimeGrid"
                  :allowExcelExport="true"
                  :allowGrouping="true"
                  :allowResizing="true"
                  :provides="reservationTimeProps.provides"
                  :groupSettings="reservationTimeProps.groupSettings"
                  :columns="reservationTimeProps.columns"
                  :dataSource="reservationTimeList"
                  :aggregates="reservationTimeProps.aggregates"
                  @headerCellInfo="reservationTimeGridHeaderCellInfo"
                  @queryCellInfo="reservationTimeGridQueryCellInfo"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<style scoped>
body .appContent .body-article .section-body {overflow: hidden; border: none;}
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import moment from 'moment';
import commonMixin from '@/views/layout/mixin/commonMixin';
import InputDate from '@/components/common/datetime/InputDate';
import ReportView from '@/components/common/report/ReportView';
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import {
  commonCodesGetCommonCode,
  commonCodesGetComName,
  commonCodesGetCodeAbrv,
  commonCodesGetColorValue,
  commonCodesGetCommonCodeAttrbByCodeAndIdx,
} from '@/utils/commonCodes';
import {
  getFormattedDate,
  getDayOfWeekCaption,
  getDayOfWeekCaptionColor,
  getWeekdayWeekendCaption,
  getWeekdayWeekendCaptionColor,
} from '@/utils/date';
import {mapGetters} from 'vuex';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import {numberWithCommas} from '@/utils/number';
import EjsGridWrapper from '@/components/common/EjsGridWrapper';
import {Aggregate, ExcelExport, Filter, ForeignKey, Group, Resize} from '@syncfusion/ej2-vue-grids';
import {gridUtilGetMemberNoColumnAccess} from "@/utils/gridUtil";
import {maskingNameFormat2, memberNoFormatter} from "@/utils/formatter";

export default {
  name: 'ReservationStatusByGameProgress',
  components: {
    InputDate,
    ReportView,
    EjsGridWrapper,
    ErpButton
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  filters: {
    numberWithCommas,
  },
  async created() {
    this.searchConditions.resveDate = getFormattedDate(new Date());
    this.resveDate = getFormattedDate(new Date());
    this.courseDivOptions = commonCodesGetCommonCode("COURSE_DIV", true);
    this.courseDivOptions.unshift({ comCode: "%", comName: "전체" });
    await this.fetch();
  },
  data() {
    return {
      searchConditions: {
        resveDate: null,
      },
      resveTransNameDiv: 'RESVE',
      notContactTellFlag: false,
      notResveRemarksFlag: false,
      reservationStatusDivTapList: [
        {
          header: {text: '코스별'},
          statusDiv: 'COURSE',
        },
        {
          header: {text: '부별'},
          statusDiv: 'PART',
        },
      ],
      coursePart:{
        chapter1: true,
        chapter2: true,
        chapter3: true,
      },
      isLineOption: false,
      resveDate: null,
      weatherDiv: null,
      statusDiv: 'COURSE',
      reservationStatusList: [],
      reservationStatusInfo: {},
      reservationTimeList: [],
      partDiv: commonCodesGetCommonCode('PART_DIV', true),
      isSmallMode: '',
      courseDiv: "%",
      courseDivOptions: null,
      commonCodeFields: { text: "comName", value: "comCode" },
    };
  },
  computed: {
    ...mapGetters(['username']),
    courseCode() {
      const courseCode =
          commonCodesGetCommonCode('COURSE_CODE', true)
              .filter(course => this.courseDiv === "%" ? true : course.comCodeAttrbList.filter(attrb => attrb.idx === 2 && attrb.attrb === this.courseDiv).length > 0);

      return courseCode;
    },
    reservationStatusProps() {
      let columns = [];
      let aggregatesColumns = [];
      columns.push({
        field: 'partDiv1',
        type: 'String',
        isPrimaryKey: true,
        visible: false,
      });

      this.courseCode.map((code, idx) => {
        columns.push(
          {
            headerText: code.comName,
            columns: [
              {
                field: `resveTime${idx + 1}`,
                headerText: '시간',
                textAlign: 'center',
                width: 60,
                type: 'string',
              },
              {
                field: `resveName${idx + 1}`,
                headerText: '예약자명',
                width: 90,
                type: 'string',
              },
              {
                field: `memberNo${idx + 1}`,
                headerText: '회원번호',
                textAlign: "center",
                width: 90,
                visible: false,
                type: 'string',
                valueAccessor: (field, data) =>
                  gridUtilGetMemberNoColumnAccess(field, data),
              },
              {
                field: `resveRemarks${idx + 1}`,
                headerText: '예약비고',
                textAlign: "center",
                width: 90,
                type: 'string',
              },
              {
                field: `caddie${idx + 1}`,
                headerText: '캐디',
                width: 90,
                type: 'string',
              },
            ]
          }
        );
        aggregatesColumns.push(
          {
            field: `resveTime${idx + 1}`,
            aggregationType: 'GroupCaption',
            customAggregate: aggregates => {
              return aggregates
                ? aggregates.items[0].partDivName
                : '';
            },
          },
          {
            field: `resveName${idx + 1}`,
            aggregationType: 'GroupCaption',
            customAggregate: aggregates => {
              return (aggregates
                ? aggregates.items.filter(item => item[`resveName${idx + 1}`]).length
                : 0) + ' 팀';
            },
            textAlign: 'right',
          },
        );
      });
      return {
        provides: [
          Aggregate,
          Filter,
          Resize,
          Group,
          ExcelExport,
          ForeignKey,
        ],
        groupSettings: {
          columns: ['partDiv1'],
          showDropArea: false,
        },
        columns: columns,
        aggregates: [
          {
            columns: aggregatesColumns,
          },
        ],
      };
    },
    reservationTimeProps() {
      let columns = [];
      let aggregatesCourseColumns = [];
      let aggregatesPartColumns = [];

      this.partDiv.map((part, partIdx) => {
        let courseColumns = [];
        this.courseCode.map((course, courseIdx) => {
          courseColumns.push(
            {
              headerText: course.comName,
              devVerticalLine: partIdx !== 0 && courseIdx === 0,
              columns: [
                {
                  field: `resveTime_P${part.comCode}_C${courseIdx + 1}`,
                  headerText: '시간',
                  textAlign: 'center',
                  width: 60,
                  type: 'string',
                  devVerticalLine: partIdx !== 0 && courseIdx === 0,
                },
                {
                  field: `resveName_P${part.comCode}_C${courseIdx + 1}`,
                  headerText: '예약자',
                  width: 90,
                  type: 'string',
                },
                {
                  field: `memberNo_P${part.comCode}_C${courseIdx + 1}`,
                  headerText: '회원번호',
                  textAlign: "center",
                  width: 90,
                  type: 'string',
                  valueAccessor: (field, data) =>
                    gridUtilGetMemberNoColumnAccess(field, data),
                },
                {
                  field: `resveRemarks_P${part.comCode}_C${courseIdx + 1}`,
                  headerText: '비고',
                  width: 90,
                  type: 'string',
                },
              ]
            }
          );
          aggregatesCourseColumns.push(
            {
              field: `resveName_P${part.comCode}_C${courseIdx + 1}`,
              aggregationType: 'TotalCaption',
              customAggregate: () => {
                const gridData = this.$refs.reservationTimeGrid.getGridBatchData();
                return (gridData
                  ?.filter(item => item[`resveName_P${part.comCode}_C${courseIdx + 1}`])
                  ?.length || 0) + ' 건';
              },
              textAlign: 'right',
            },
          );
        });

        columns.push(
          {
            headerText: part.comName,
            devVerticalLine: partIdx !== 0,
            columns: courseColumns,
          }
        );

        aggregatesPartColumns.push(
          {
            field: `resveName_P${part.comCode}_C2`,
            aggregationType: 'TotalCaption',
            customAggregate: () => {
              const gridData = this.$refs.reservationTimeGrid.getGridBatchData();
              let count = 0;
              gridData
                ?.forEach(item => {
                  for (let c = 1; c <= this.courseCode.length; c++) {
                    if (item[`resveName_P${part.comCode}_C${c}`]) {
                      count++;
                    }
                  }
                });
              return `${part.comName} : ${count}T`;
            },
            textAlign: 'right',
          },
        );
      });

      return {
        provides: [
          Aggregate,
          Filter,
          Resize,
          Group,
          ExcelExport,
          ForeignKey,
        ],
        groupSettings: {
          columns: [],
          showDropArea: false,
        },
        columns: columns,
        aggregates: [
          {
            columns: aggregatesCourseColumns,
          },
          {
            columns: aggregatesPartColumns,
          },
        ],
      };
    },
  },
  methods: {
    commonCodesGetColorValue,
    getDayOfWeekCaption,
    getDayOfWeekCaptionColor,
    getWeekdayWeekendCaption,
    getWeekdayWeekendCaptionColor,
    async onReservationStatusViewClicked() {
      await this.fetch();
    },
    async tabSelected(args) {
      this.statusDiv = this.reservationStatusDivTapList[args.selectedIndex].statusDiv;
      await this.fetch();
    },
    gridFilterCheckChapter() {
      this.fetch();
    },
    reservationStatusGridHeaderCellInfo(args) {
      const {
        cell: {column: {field, headerText}},
        node,
      } = args;
      if (
        this.courseCode.map((code, idx) => {
          return idx !== 0 ? `resveTime${code.comCode}` : null;
        }).includes(field) ||
        this.courseCode.map((code, idx) => {
          return idx !== 0 ? code.comName : null;
        }).includes(headerText)
      ) {
        node.classList.add(this.$t('className.grid.devVerticalLine'));
      }
    },
    reservationStatusGridQueryCellInfo(args) {
      const {
        column: {field},
        cell,
        data,
      } = args;
      if (this.courseCode.map(code => `resveTime${code.comCode}`).includes(field)) {
        const courseCode = field.slice(9, field.length);
        if (!data[field]) {
          cell.style.backgroundColor = '#CCC';
        } else if (data[`cancelDiv${courseCode}`] === 'RAIN') {
          cell.style.backgroundColor = commonCodesGetColorValue('CANCEL_DIV', data[`cancelDiv${courseCode}`]);
          cell.style.color = '#FFF';
        } else if (data[`timeStatus${courseCode}`] === 'HOLD') {
          cell.style.backgroundColor = commonCodesGetColorValue('TIME_STATUS', data[`timeStatus${courseCode}`]);
          cell.style.color = '#FFF';
        }
      }
      if (
        this.courseCode.map((code, idx) => {
          return idx !== 0 ? `resveTime${code.comCode}` : null;
        }).includes(field)
      ) {
        cell.classList.add(this.$t('className.grid.devVerticalLine'));
      }
      if (this.courseCode.map(code => `resveRemarks${code.comCode}`).includes(field)) {
        const courseCode = field.slice(12, field.length);
        if (data[`timeStatus${courseCode}`] === 'HOLD') {
          //args.cell.innerHTML = `홀딩예약 / ${args.cell.innerHTML}`;
          cell.innerHTML  = "홀딩 " + cell.innerHTML;
        }
      }
    },
    reservationTimeGridHeaderCellInfo(args) {
      const {
        cell: {column: {devVerticalLine}},
        node,
      } = args;
      if (devVerticalLine) {
        node.classList.add(this.$t('className.grid.devVerticalLine'));
      }
    },
    reservationTimeGridQueryCellInfo(args) {
      const {
        column: {field},
        cell,
        data,
      } = args;
      if (field && field.startsWith("resveTime")) {
        const courseCode = field.slice(9, field.length);
        if (!data[field]) {
          cell.style.backgroundColor = '#CCC';
        } else if (data[`cancelDiv${courseCode}`] === 'RAIN') {
          cell.style.backgroundColor = commonCodesGetColorValue('CANCEL_DIV', data[`cancelDiv${courseCode}`]);
          cell.style.color = '#FFF';
        } else if (data[`timeStatus${courseCode}`] === 'HOLD') {
          cell.style.backgroundColor = commonCodesGetColorValue('TIME_STATUS', data[`timeStatus${courseCode}`]);
          cell.style.color = '#FFF';
        }
      }
      if (field && field.startsWith("resveTime") && !field.startsWith("resveTime_P1") && field.endsWith("C1")) {
        cell.classList.add(this.$t('className.grid.devVerticalLine'));
      }
    },
    postReportCourse() {
      const courseCount = this.courseCode.length;
      let jsonData = {
        course: this.reservationStatusList.map(item => ({
          ...item,
          resveName1: item.resveMaskingName1, // 예약자명 마스킹 적용 안하면 주석처리함...
          resveName2: item.resveMaskingName2,
          resveName3: item.resveMaskingName3,
          memberNo1: memberNoFormatter(item.memberNo1),
          memberNo2: memberNoFormatter(item.memberNo2),
          memberNo3: memberNoFormatter(item.memberNo3),
        })),
        ...this.reservationStatusInfo,
      };
      for (let c = 0; c < courseCount; c++) {
        const block = {};
        block[`courseName${c + 1}`] = this.courseCode[c]?.comName || '';
        jsonData = Object.assign(jsonData, block);
      }
      const weatherDiv = commonCodesGetComName('WEATHER_CODE', this.weatherDiv);
      const reportData = {
        reportJson: {
          jsonData,
          username: this.username,
          uniqueCode: this.$options.name,
          weather: weatherDiv ? ` (${weatherDiv})` : '',
          resveDate: moment(this.resveDate).format('YYYY년 MM월 DD일 (dd)'),
          transFlag: this.resveTransNameDiv === 'TRANS' ? 'true' : 'false',
          courseCount: this.courseCode.length,
        },
      };
      const type = `ReservationStatus${courseCount}ByGameProgress`;
      this.$refs.reportViewComponent.postReport(reportData, type);
    },
    async postReportPart() {
      const courseCount = this.courseCode.length;
      const partCount = this.partDiv.length;
      let jsonData = {
        course: this.reservationTimeList.map(item => ({
          ...item,
          resveName_P1_C1: item.resveMaskingName_P1_C1, // 예약자명 마스킹 적용 안하면 주석처리함...
          resveName_P1_C2: item.resveMaskingName_P1_C2,
          resveName_P1_C3: item.resveMaskingName_P1_C3,
          resveName_P2_C1: item.resveMaskingName_P2_C1, // 예약자명 마스킹 적용 안하면 주석처리함...
          resveName_P2_C2: item.resveMaskingName_P2_C2,
          resveName_P2_C3: item.resveMaskingName_P2_C3,
          resveName_P3_C1: item.resveMaskingName_P3_C1, // 예약자명 마스킹 적용 안하면 주석처리함...
          resveName_P3_C2: item.resveMaskingName_P3_C2,
          resveName_P3_C3: item.resveMaskingName_P3_C3,
          memberNo_P1_C1: memberNoFormatter(item.memberNo_P1_C1),
          memberNo_P1_C2: memberNoFormatter(item.memberNo_P1_C2),
          memberNo_P1_C3: memberNoFormatter(item.memberNo_P1_C3),
          memberNo_P2_C1: memberNoFormatter(item.memberNo_P2_C1),
          memberNo_P2_C2: memberNoFormatter(item.memberNo_P2_C2),
          memberNo_P2_C3: memberNoFormatter(item.memberNo_P2_C3),
        })),
      };
      for (let c = 0; c < courseCount; c++) {
        const block = {};
        block[`courseName${c + 1}`] = this.courseCode[c]?.comName || '';
        jsonData = Object.assign(jsonData, block);
      }
      const reportData = {
        reportJson: {
          jsonData,
          username: this.username,
          uniqueCode: this.$options.name,
          resveDate: moment(this.resveDate).format('YYYY년 MM월 DD일 (dd)'),
          transFlag: this.resveTransNameDiv === 'TRANS' ? 'true' : 'false',
          courseCount,
        },
      };
      const type = `ReservationStatusCourse${courseCount}Part${partCount}`;
      this.$refs.reportViewComponent.postReport(reportData, type);
    },
    print() {
      switch (this.statusDiv) {
        case 'COURSE':
          this.postReportCourse();
          break;
        case 'PART':
          this.postReportPart();
          break;
      }
    },
    excel() {
      switch (this.statusDiv) {
        case 'COURSE':
          this.$refs.reservationStatusGrid.excelExport();
          break;
        case 'PART':
          this.$refs.reservationTimeGrid.excelExport();
          break;
      }
    },
    cellphoneNumberFormatter(data) {
      if (data === undefined || data === null) {
        return '';
      }
      if (
          /^([\d]){3}-([\w*]){3}-([\d]){4}$/.test(data) ||
          /^([\d]){3}-([\w*]){4}-([\d]){4}$/.test(data)
      ) {
        return data;
      }
      if (/^([\d]){3}([\w*]){3}([\d]){4}$/.test(data)) {
        return data.replace(/^(\d{3})([\w*]{3})(\d{4})$/, '$1-$2-$3');
      }
      if (/^([\d]){3}([\w*]){4}([\d]){4}$/.test(data)) {
        return data.replace(/^(\d{3})([\w*]{4})(\d{4})$/, '$1-$2-$3');
      }
      return data;
    },
    async fetch() {
      const resveDate = this.searchConditions.resveDate;
      if (!resveDate) {
        this.errorToast(this.$t('main.validationMessage.requiredMessage', ['예약일자']));
        return;
      }
      const courseCount = this.courseCode.length;
      const partCount = 3;
      const {data: weatherInfo} = await GolfErpAPI.fetchWeatherInfo({bsnDate: resveDate});
      this.resveDate = JSON.parse(JSON.stringify(resveDate));
      this.weatherDiv = weatherInfo?.weatherDiv || '맑음';
      let reservationStatusList = await GolfErpAPI.fetchReservationStatus(resveDate);
      reservationStatusList =
          reservationStatusList.filter(
              item => this.courseDiv === "%" ? true : commonCodesGetCommonCodeAttrbByCodeAndIdx("COURSE_CODE", item.resveCourse, 2) === this.courseDiv)
              .filter(item => this.coursePart[`chapter${item.partDiv}`]);
      let reservationTimeList = [];
      reservationStatusList.forEach((data) => {
        if (reservationTimeList.findIndex(item => item.resveTime === data.resveTime) === -1) {
          const pushData = {
            resveDate: data.resveDate,
            resveTime: data.resveTime,
            partDiv: data.partDiv,
          };
          reservationTimeList.push(pushData);
        }
      });
      reservationTimeList.sort((a, b) => {
        const [aHours, aMinutes] = a.resveTime.split(':');
        const [bHours, bMinutes] = b.resveTime.split(':');
        const aTime = aHours + aMinutes;
        const bTime = bHours + bMinutes;
        return aTime - bTime;
      });
      let tempTimeCourseList = [];
      for (let p = 1; p <= partCount; p++) {
        if (this.isLineOption) {
          reservationTimeList
              .filter(item => item.partDiv === p.toString())
              .map(item => {
                let list = [];
                for (let c = 0; c < courseCount; c++) {
                  const info = reservationStatusList
                      .filter(i => i.partDiv === p.toString())
                      .find(
                          i =>
                              i.resveCourse === this.courseCode[c]?.comCode &&
                              i.resveTime === item.resveTime,
                      );
                  if (info) {
                    const block = {};
                    const suffix = c + 1;
                    block[`resveTime${suffix}`] = info.resveTime || null;
                    if (this.resveTransNameDiv === 'TRANS' && info.transName) {
                      block[`resveName${suffix}`] = info.resveId
                          ? commonCodesGetCodeAbrv('MEMBER_DIV', info.transMemberDiv)
                              ? `${commonCodesGetCodeAbrv('MEMBER_DIV', info.transMemberDiv)} ${info.transName}`
                              : info.transName
                          : null;
                      block[`resveMaskingName${suffix}`] = info.resveId
                          ? commonCodesGetCodeAbrv('MEMBER_DIV', info.transMemberDiv)
                              ? `${commonCodesGetCodeAbrv('MEMBER_DIV', info.transMemberDiv)} ${maskingNameFormat2(info.transName||"")}`
                              : maskingNameFormat2(info.transName||"")
                          : null;
                    } else {
                      block[`resveName${suffix}`] = info.resveId
                          ? commonCodesGetCodeAbrv('MEMBER_DIV', info.memberDiv)
                              ? `${commonCodesGetCodeAbrv('MEMBER_DIV', info.memberDiv)} ${info.resveName}`
                              : info.resveName
                          : null;
                      block[`resveMaskingName${suffix}`] = info.resveId
                          ? commonCodesGetCodeAbrv('MEMBER_DIV', info.memberDiv)
                              ? `${commonCodesGetCodeAbrv('MEMBER_DIV', info.memberDiv)} ${maskingNameFormat2(info.resveName||"")}`
                              : maskingNameFormat2(info.resveName||"")
                          : null;
                      block[`memberNo${suffix}`] = info.memberNo;
                    }
                    block[`grpName${suffix}`] = info.grpName || null;
                    if (!this.notContactTellFlag) {
                      block[`contactTel${suffix}`] = info.contactTel
                          ? this.cellphoneNumberFormatter(info.contactTel)
                          : null;
                    }
                    if (!this.notResveRemarksFlag && this.commonMixinHasCiperTextGet) {
                      block[`resveRemarks${suffix}`] = info.resveRemarks || null;
                    }
                    block[`cancelDiv${suffix}`] = info.cancelDiv || null;
                    block[`timeStatus${suffix}`] = info.timeStatus || null;
                    block.partDiv1 = info.partDiv;
                    block.partDivName = commonCodesGetComName('PART_DIV', info.partDiv);
                    block[`tempCount${suffix}`] = info.resveId ? 1 : 0;
                    block[`caddie${suffix}`] = info.caddieName || null;
                    list.push(block);
                  }
                }
                tempTimeCourseList.push(Object.assign(...list));
              });
        } else {
          let rowCount = 0;
          for (let c = 0; c < courseCount; c++) {
            const filterCount =
                reservationStatusList
                    .filter(item =>
                        item.partDiv === p.toString() &&
                        item.resveCourse === this.courseCode[c]?.comCode
                    ).length;

            rowCount = rowCount < filterCount ? filterCount : rowCount;
          }

          for (let r = 0; r < rowCount; r++) {
            let list = [];
            for (let c = 0; c < courseCount; c++) {
              const info = reservationStatusList
                  .filter(item => item.partDiv === p.toString() && item.resveCourse === this.courseCode[c]?.comCode)[r];
              if (info) {
                const block = {};
                const suffix = c + 1;
                block[`resveTime${suffix}`] = info.resveTime || null;
                if (this.resveTransNameDiv === 'TRANS' && info.transName) {
                  block[`resveName${suffix}`] = info.resveId
                      ? commonCodesGetCodeAbrv('MEMBER_DIV', info.transMemberDiv)
                          ? `${commonCodesGetCodeAbrv('MEMBER_DIV', info.transMemberDiv)} ${info.transName}`
                          : info.transName
                      : null;
                  block[`resveMaskingName${suffix}`] = info.resveId
                      ? commonCodesGetCodeAbrv('MEMBER_DIV', info.transMemberDiv)
                          ? `${commonCodesGetCodeAbrv('MEMBER_DIV', info.transMemberDiv)} ${maskingNameFormat2(info.transName||"")}`
                          : maskingNameFormat2(info.transName||"")
                      : null;
                } else {
                  block[`resveName${suffix}`] = info.resveId
                      ? commonCodesGetCodeAbrv('MEMBER_DIV', info.memberDiv)
                          ? `${commonCodesGetCodeAbrv('MEMBER_DIV', info.memberDiv)} ${info.resveName}`
                          : info.resveName
                      : null;
                  block[`resveMaskingName${suffix}`] = info.resveId
                      ? commonCodesGetCodeAbrv('MEMBER_DIV', info.memberDiv)
                          ? `${commonCodesGetCodeAbrv('MEMBER_DIV', info.memberDiv)} ${maskingNameFormat2(info.resveName||"")}`
                          : maskingNameFormat2(info.resveName||"")
                      : null;
                  block[`memberNo${suffix}`] = info.memberNo;
                }
                block[`grpName${suffix}`] = info.grpName || null;
                if (!this.notContactTellFlag) {
                  block[`contactTel${suffix}`] = info.contactTel
                      ? this.cellphoneNumberFormatter(info.contactTel)
                      : null;
                }
                if (!this.notResveRemarksFlag && this.commonMixinHasCiperTextGet) {
                  block[`resveRemarks${suffix}`] = info.resveRemarks || null;
                }
                block[`cancelDiv${suffix}`] = info.cancelDiv || null;
                block[`timeStatus${suffix}`] = info.timeStatus || null;
                block.partDiv1 = info.partDiv;
                block.partDivName = commonCodesGetComName('PART_DIV', info.partDiv);
                block[`tempCount${suffix}`] = info.resveId ? 1 : 0;
                block[`caddie${suffix}`] = info.caddieName || null;
                list.push(block);
              }
            }
            tempTimeCourseList.push(Object.assign(...list));
          }
        }
      }
      const courseReservationListPartTotalSet = partDiv => {
        let count = 0;
        reservationStatusList.forEach(data => {
          if (data.resveId && (partDiv === 'ALL' || partDiv === data.partDiv)) {
            count++;
          }
        });
        return count;
      };
      this.reservationStatusList = tempTimeCourseList;
      this.reservationStatusInfo = {
        courseName1: this.courseCode.length >= 1 && this.courseCode[0]?.comName,
        courseName2: this.courseCode.length >= 2 && this.courseCode[1]?.comName,
        courseName3: this.courseCode.length >= 3 && this.courseCode[2]?.comName,
        allPartDivAllTeamCount: courseReservationListPartTotalSet('ALL'),
        allPartDiv1TeamCount: this.partDiv.length >= 1 ? courseReservationListPartTotalSet('1') : null,
        allPartDiv2TeamCount: this.partDiv.length >= 2 ? courseReservationListPartTotalSet('2') : null,
        allPartDiv3TeamCount: this.partDiv.length >= 3 ? courseReservationListPartTotalSet('3') : null,
      };
      let tempTimePartList = [];
      for (let p = 1; p <= partCount; p++) {
        tempTimePartList[p - 1] = [];
        reservationTimeList
            .filter(item => item.partDiv === p.toString())
            .map(item => {
              let list = [];

              for (let c = 0; c < 3; c++) {
                const info = reservationStatusList
                    .filter(i => i.partDiv === p.toString())
                    .find(
                        i =>
                            i.resveCourse === this.courseCode[c]?.comCode &&
                            i.resveTime === item.resveTime,
                    );
                if (info) {
                  const block = {};
                  const suffix = `_P${p}_C${c + 1}`;
                  block[`resveTime${suffix}`] = info.resveTime || null;
                  if (this.resveTransNameDiv === 'TRANS' && info.transName) {
                    block[`resveName${suffix}`] = info.resveId
                        ? commonCodesGetCodeAbrv('MEMBER_DIV', info.transMemberDiv)
                            ? `${commonCodesGetCodeAbrv('MEMBER_DIV', info.transMemberDiv)} ${info.transName}`
                            : info.transName
                        : null;
                    block[`resveMaskingName${suffix}`] = info.resveId
                        ? commonCodesGetCodeAbrv('MEMBER_DIV', info.transMemberDiv)
                            ? `${commonCodesGetCodeAbrv('MEMBER_DIV', info.transMemberDiv)} ${maskingNameFormat2(info.transName||"")}`
                            : maskingNameFormat2(info.transName||"")
                        : null;
                  } else {
                    block[`resveName${suffix}`] = info.resveId
                        ? commonCodesGetCodeAbrv('MEMBER_DIV', info.memberDiv)
                            ? `${commonCodesGetCodeAbrv('MEMBER_DIV', info.memberDiv)} ${info.resveName}`
                            : info.resveName
                        : null;
                    block[`resveMaskingName${suffix}`] = info.resveId
                        ? commonCodesGetCodeAbrv('MEMBER_DIV', info.memberDiv)
                            ? `${commonCodesGetCodeAbrv('MEMBER_DIV', info.memberDiv)} ${maskingNameFormat2(info.resveName||"")}`
                            : maskingNameFormat2(info.resveName||"")
                        : null;
                    block[`memberNo${suffix}`] = info.memberNo;
                  }
                  if (!this.notResveRemarksFlag && this.commonMixinHasCiperTextGet) {
                    block[`resveRemarks${suffix}`] = info.resveRemarks || null;
                  }
                  block[`cancelDiv${suffix}`] = info.cancelDiv || null;
                  block[`timeStatus${suffix}`] = info.timeStatus || null;
                  block[`tempCount${suffix}`] = info.resveId ? 1 : 0;
                  list.push(block);
                }
              }
              if (list.length > 0) {
                tempTimePartList[p - 1].push(Object.assign(...list));
              }
            });
      }
      let arrayMax = 0;
      for (let p = 0; p < partCount; p++) {
        if (arrayMax <= tempTimePartList[p].length) {
          arrayMax = tempTimePartList[p].length;
        }
      }
      let result = [];
      for (let i = 0; i < arrayMax; i++) {
        let temp = [];
        for (let p = 0; p < partCount; p++) {
          if (tempTimePartList[p][i]) {
            temp.push(tempTimePartList[p][i]);
          }
        }
        result.push(Object.assign(...temp));
      }
      this.reservationTimeList = result;
    },
    onCourseDivChanged() {
      this.fetch();
    }
  },
};
</script>
